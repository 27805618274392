const enableMenuNavigation = (menu: Element, closeHandler?: () => void, trigger?: HTMLElement) => {
    const focusableSelectors = '[role="menuitem"], a, button, input, [tabindex]:not([tabindex="-1"])';
    const ACTIVE_INDEX_ATTR = 'data-active-index';
    const EVENT_LISTENER_ATTR = 'data-menu-navigation-enabled';

    // Check if listeners are already attached
    if (menu.hasAttribute(EVENT_LISTENER_ATTR)) {
        return;
    }
    menu.setAttribute(EVENT_LISTENER_ATTR, 'true');

    if (!menu.hasAttribute(ACTIVE_INDEX_ATTR)) {
        menu.setAttribute(ACTIVE_INDEX_ATTR, '0');
    }

    const handleFocusOut = (event: FocusEvent) => {
        if (!menu.contains(event.relatedTarget as Node)) {
            closeHandler?.();
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            closeHandler?.();
            trigger?.focus();
            event.preventDefault();
            return;
        }

        if (event.key === "Tab") {
            closeHandler?.();
            return;
        }

        if (!["ArrowDown", "ArrowUp", "Home", "End"].includes(event.key)) return;

        const focusableItems = Array.from(menu.querySelectorAll<HTMLElement>(focusableSelectors));
        if (focusableItems.length === 0) return;


        if (focusableItems.length === 1) {
            focusableItems[0].focus();
            event.preventDefault();
            return;
        }

        // Get current index from the menu's data attribute
        let currentIndex = parseInt(menu.getAttribute(ACTIVE_INDEX_ATTR) || '0', 10);

        // Validate current index
        if (currentIndex < 0 || currentIndex >= focusableItems.length) {
            currentIndex = 0;
        }

        let nextIndex;
        switch (event.key) {
            case "ArrowDown":
                nextIndex = (currentIndex + 1) % focusableItems.length;
                break;
            case "ArrowUp":
                nextIndex = (currentIndex - 1 + focusableItems.length) % focusableItems.length;
                break;
            case "Home":
                nextIndex = 0;
                break;
            case "End":
                nextIndex = focusableItems.length - 1;
                break;
            default:
                return;
        }

        // Update the active index
        menu.setAttribute(ACTIVE_INDEX_ATTR, nextIndex.toString());
        focusableItems[nextIndex].focus();
        event.preventDefault();
        event.stopImmediatePropagation(); // More aggressive than stopPropagation
    };

    const handleItemFocus = (event: Event) => {
        const target = event.target as HTMLElement;
        const focusableItems = Array.from(menu.querySelectorAll<HTMLElement>(focusableSelectors));
        const index = focusableItems.indexOf(target);
        if (index >= 0) {
            menu.setAttribute(ACTIVE_INDEX_ATTR, index.toString());
        }
    };
    const handleItemClick = (event: Event) => {
        // Close the menu when any item is clicked
        //closeHandler?.();

        // If the clicked item is a link or button, let the default action proceed
        const target = event.target as HTMLElement;
        if (target.tagName !== 'A' && target.tagName !== 'BUTTON') {
            event.preventDefault();
        }

        // Return focus to the trigger if specified
        trigger?.focus();
    };

    menu.addEventListener("focusout", handleFocusOut);
    menu.addEventListener("keydown", handleKeyDown);

    // Update index when items are clicked or focused
    const menuItems = menu.querySelectorAll<HTMLElement>(focusableSelectors);
    menuItems.forEach(item => {
        item.addEventListener('focus', handleItemFocus);
        item.addEventListener('click', handleItemClick);
    });

    return () => {
        menu.removeEventListener("focusout", handleFocusOut);
        menu.removeEventListener("keydown", handleKeyDown);
        menuItems.forEach(item => {
            item.removeEventListener('focus', handleItemFocus);
        });
        menu.removeAttribute(EVENT_LISTENER_ATTR);
    };
};


export {enableMenuNavigation}