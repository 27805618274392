import {enableMenuNavigation} from "./menu"

const keydownHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        (event.target as HTMLElement).click();
    }
};

const addEnterAndSpaceHandler = () => {
    const buttonElements = document.querySelectorAll('[role="button"]');

    buttonElements.forEach(element => {
        if ((element as HTMLElement).dataset.listenerAdded) return;

        const keydownHandler = (event: KeyboardEvent) => {
            if ((event.key === 'Enter' || event.key === ' ') && !event.repeat) {
                event.preventDefault();
                (element as HTMLElement).click();
            }
        };

        element.addEventListener('keydown', keydownHandler);
        (element as HTMLElement).dataset.listenerAdded = "true";
    });
};

export const removeEnterAndSpaceHandler = () => {
    const buttonElements = document.querySelectorAll('[role="button"]');

    buttonElements.forEach(element => {
        element.removeEventListener('keydown', keydownHandler);
    });
}
/**
 *  Helper function to add anchors above main elements
 */
export const setupSkipToContent = () => {
    const mainElement = document.querySelector('main');
    const skipToContentButton = document.querySelector('#skip-to-content');

    if (!mainElement || !skipToContentButton) return;

    const anchor = Object.assign(document.createElement('div'), {
        id: "main-content-anchor",
        tabIndex: -1
    });

    // attach anchor as a sibling before <main>
    mainElement.before(anchor);

    // workaround to make sure the content gets focussed on button click
    skipToContentButton.addEventListener('click', function(){
        anchor.focus();
    });
}

export { addEnterAndSpaceHandler }
