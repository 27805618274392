import {enableMenuNavigation} from "@utils/accessibility/menu";

const setupPuesAccessibility = () => {
    const toggleMenu = (menu: HTMLElement, forceState?: boolean) => {
        const isOpen = menu.classList.contains("active");
        const newState = forceState !== undefined ? forceState : !isOpen;
        menu.classList.toggle("active", newState);
        if(newState){
            const firstItem = menu.querySelector<HTMLElement>('[role="menuitem"], a, button, input, [tabindex]:not([tabindex="-1"])');
            firstItem?.focus();
        }
    };

    // open menus on click
    document.querySelectorAll(".product-sorting .sort-set .sort-selected").forEach((menu) => {
        if (menu.hasAttribute("data-click-handler-added")) {
            return;
        }
        menu.setAttribute("data-click-handler-added", "true");
        menu.addEventListener("click", ()=>{
            menu.querySelectorAll<HTMLElement>(".sort-selection").forEach((list)=>{
                toggleMenu(list)
            })
        })
    })

    // make them keyboard navigatable
    document.querySelectorAll(".product-sorting .sort-set").forEach((menuContainer) => {
        menuContainer.querySelectorAll(".sort-selection").forEach((list)=>{
            enableMenuNavigation(list, ()=>{
                    toggleMenu(list as HTMLElement, false)
                }, menuContainer.querySelector(".sort-selected") as HTMLElement
            )
        })
    });
}

export {setupPuesAccessibility}